<template>
	<div class="bpb-checkbox-container">
		<div :class="'bpb-checkbox'+checkbox_type">
			<input v-model="input" :ref="ref" type="checkbox" class="checkbox-input" :name="name" :id="id" @change="checkInput" :value="value">
			<span class="checkbox-x">
				<span class="checkbox-check">
					<i class="gg-check"></i>
				</span>
			</span>
			<span v-if="label" class="checkbox-label">{{ label }}</span>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			value: null
			,model: null
			,id: null
			,name: null
			,label: null
			,type: null
			,labelColor: null
			// ,ref: null
		}
		,model: {
			prop: 'model'
			,event: 'checked'
		}
		,data: function() {
			return {
				checked: null
				,input: null
				,setCheckboxBgColor: null
				,setLabelColor: null
				,ref: null
			}
		}
		,created: function() {
			if (!this.ref)
				this.ref = 'bpbcheckbox'+this.$helper.strRand(8);
		}
		,mounted: function() {
			const dataset = this.$el.dataset;
			const input = this.$refs[this.ref];
			const datasetKeys = Object.keys(dataset);

			if (datasetKeys.length > 1)
				datasetKeys.forEach(prop => {
					input.dataset[prop] = dataset[prop];
				});

			this.input = this.model;

			// this.$log.info('tipo de modelo: ', this.$attrs.model);
			// this.$log.info('tipo de modelo: ', this.model);
			// if (typeof this.model != 'object') {
			// 	this.$log.info('el modelo es string');
			// 	this.$refs[this.ref].checked = this.model == this.value;
			// }else {
			// 	this.$log.info('El modelo es array: ',this.model.indexOf(this.value));
			// 	this.$refs[this.ref].checked = this.model.indexOf(this.value) > -1;
			// }
		}
		,methods: {
			checkInput: function(e) {
				this.checked = e.target.checked;

				let name = this.$refs[this.ref].name;
				// this.$log.info('name', name);

				if (name.indexOf('[]') > -1) {
					// name = name.replace('[]','');
					let checkbox = document.querySelectorAll('input[name="'+name+'"]:checked');
					// this.$log.info('checkbox',checkbox);

					let valores = [];
					checkbox.forEach(input => {
						valores.push(input.value);
					})

					this.$emit('checked', valores);
				}else {
					// this.$log.info('input', this.input);
					this.$emit('checked', this.input);
				}
			}
		}
		,computed: {
			checkbox_type: function() {
				const types = ['primary','success','warning','danger','info','secondary','dark','light'];

				if (types.indexOf(this.type) > -1)
					return ' bpb-checkbox-type-'+this.type;

				return ' bpb-checkbox-type-primary';
			}
		}
		,watch: {
			labelColor: function(val) {
				let tipos = Object.keys(this.colores);

				this.setLabelColor = 'color:'+(tipos.indexOf(val) > -1 ? this.colores[val] : this.colores.light)+';';
			}
			,model: function(val, oldVal) {
				// this.$log.info('val', val);
				// this.$log.info('oldVal', oldVal);

				if (isNaN(val))
					this.$refs[this.ref].checked = val.indexOf(this.value) > -1;
				else
					this.$refs[this.ref].checked = val > this.value;
			}
		}
	}
</script>

<style lang="scss">
	.bpb-checkbox-container {
		margin: 10px 15px;
		display: inline-block;

		.bpb-checkbox {
			justify-content: flex-start !important;
			display: flex;
			position: relative;

			input[type="checkbox"].checkbox-input {
				width: 100%;
				height: 100%;
				background: #55d775;
				opacity: 0;
				z-index: 200;
				display: block !important;
				cursor: pointer;
				position: absolute;
				top: 0;
				left: 0;
			}

			input[type="checkbox"].checkbox-input:checked {
				border-radius: 2px;
			}

			span.checkbox-x {
				width: 20px;
				height: 20px;
				border: 1px solid #CCCCCC;
				background-color: #fff;
				border-radius: 2px;
				display: flex;
				overflow: hidden;
				box-sizing: border-box;

				span.checkbox-check {
					width: 100%;
					height: 100%;
					margin-top: -2px;
					margin-left: -2px;

					i {
						color: #fff;
					}
				}

			}

			span.checkbox-label {
				margin-left: 5px;
			}
		}

		.bpb-checkbox-type-primary {
			input.checkbox-input:checked+.checkbox-x {
				background-color: #0781cd;
			}
		}

		.bpb-checkbox-type-success {
			input.checkbox-input:checked+.checkbox-x {
				background-color: #55d775;
			}
		}

		.bpb-checkbox-type-warning {
			input.checkbox-input:checked+.checkbox-x {
				background-color: #f99f43;
			}
		}

		.bpb-checkbox-type-danger {
			input.checkbox-input:checked+.checkbox-x {
				background-color: #ea5455;
			}
		}

		.bpb-checkbox-type-info {
			input.checkbox-input:checked+.checkbox-x {
				background-color: #17a2b8;
			}
		}

		.bpb-checkbox-type-secondary {
			input.checkbox-input:checked+.checkbox-x {
				background-color: #6c757d;
			}
		}

		.bpb-checkbox-type-dark {
			input.checkbox-input:checked+.checkbox-x {
				background-color: #343a40;
			}
		}

		.bpb-checkbox-type-light {
			input.checkbox-input:checked+.checkbox-x {
				background-color: #fff;
			}
		}
	}
</style>