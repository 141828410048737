<template>
  <div>
    <Modal :options="{width: '40vw'}">
      <div class="title">Agregar tipo</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-6 offset-sm-6">
            <div class="input-group mb-3">
              <input v-model="filtro" type="text" class="form-control" placeholder="Filtrar tipos por nombre" aria-label="Filtrar tipos por nombre" aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn btn-primary">Filtrar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tipos_archivos">
          <div v-for="tipo in tipos_buscados" class="tipo">
            <div class="checkbox"><BpbCheckbox v-model="tipos_seleccionados" :id="'tipo_valor_'+tipo.id" name="valors_seleccionados[]" :value="tipo.valor" /></div>
            <div class="tipo_nombre">{{ tipo.nombre }}</div>
          </div>
        </div>
      </div>
      <div class="footer text-right">
        <button class="btn btn-primary mr-2" @click="agregar_tipos">Agregar</button>
        <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import BpbCheckbox from '@/components/Form/Checkbox';
export default {
  components: {
    Modal, BpbCheckbox
  }
  ,props: {
    alianza: {
      type: Object,
      required: true
    },
    tipos: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tipos_seleccionados: [],
      filtro: null
    }
  },
  methods: {
    agregar_tipos() {
      if (this.tipos_seleccionados.length == 0)
        return this.$helper.showMessage('Error','Debe seleccionar al menos un tipo','error','alert');

      let tipos_preparados = [];

      this.tipos_seleccionados.forEach(tipo => {
        tipos_preparados.push({
          tipo: tipo
        });
      });

      this.$emit('update',tipos_preparados);
    }
  },
  computed: {
    tipos_filtrados() {
      let tipos = [];

      for(let i=0; i<this.tipos.length; i++) {
        let encontrado = false;
        for (let a=0; a<this.alianza.documentos.length; a++) {
          if (this.tipos[i].valor == this.alianza.documentos[a].tipo) {
            encontrado = true;
            a = this.alianza.documentos.length;
          }
        }

        if (!encontrado)
          tipos.push(this.tipos[i]);
      }

      return tipos;
    },
    tipos_buscados() {
      let tipos = [];

      if (!this.filtro)
        return this.tipos_filtrados;
      
      let buscar = this.filtro.toLowerCase();
      this.tipos_filtrados.forEach(tipo => {
        let tipo_normalizado = tipo.nombre.toLowerCase();

        if (tipo_normalizado.indexOf(buscar) > -1)
          tipos.push(tipo);
      });

      return tipos;
    }
  }
}
</script>

<style lang="scss" scoped>
.tipos_archivos {
  .tipo {
    width: 200px;
    border: solid 1px grey;
    border-radius: 3px;
    margin: 10px;
    padding: 3px;
    display: inline-block;
    vertical-align: top;

    .checkbox, .tipo_nombre {
      display: inline-block;
    }

    .checkbox {
      width: 30px;
      vertical-align: top;

      .bpb-checkbox-container {
        margin: 5px;
      }
    }

    .tipo_nombre {
      width: calc(100% - 30px);
      text-align: right;
      padding: 5px;
    }
  }
}
</style>